var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-item-group',[_c('v-item-group',{staticClass:"text-center",attrs:{"mandatory":""},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-btn',{style:({ opacity: active ? 1 : 0.8 }),attrs:{"dense":"","text":"","tile":"","width":"25%"},on:{"click":function($event){_vm.currentStep = 0}}},[_vm._v(" 1 - Projeto "),(!_vm.canEdit)?_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"#999"}},[_vm._v(" mdi-lock ")]):_vm._e()],1)]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-btn',{style:({ opacity: active ? 1 : 0.8 }),attrs:{"dense":"","text":"","tile":"","width":"25%"},on:{"click":function($event){_vm.currentStep = 1}}},[_vm._v(" 2 - Pareamento de rubricas "),(!_vm.canEdit)?_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"#999"}},[_vm._v(" mdi-lock ")]):_vm._e()],1)]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-btn',{style:({ opacity: active ? 1 : 0.8 }),attrs:{"dense":"","text":"","tile":"","width":"25%"},on:{"click":function($event){_vm.currentStep = 2}}},[_vm._v(" 3 - Parametrização do orçamento "),(!_vm.canEdit)?_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"#999"}},[_vm._v(" mdi-lock ")]):_vm._e()],1)]}}])}),_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-btn',{style:({ opacity: active ? 1 : 0.8 }),attrs:{"dense":"","text":"","tile":"","width":"25%"},on:{"click":function($event){_vm.currentStep = 3}}},[_vm._v(" 4 - Orçamento detalhado "),(!_vm.canEdit)?_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"#999"}},[_vm._v(" mdi-lock ")]):_vm._e()],1)]}}])})],1)],1),_c('v-window',{model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('v-window-item',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(!_vm.isEdicaoProjeto ? 'Ficha de inclusão de projeto de captação' : 'Edição projeto captação')+" ")]),_c('v-card-text',[_c('v-row',{staticClass:"ml-n4 mr-0"},_vm._l((_vm.cols),function(field,index){return _c('input-v',{key:index,staticClass:"pl-4",attrs:{"colSize":field.colSize,"label":field,"opts":_vm.opts,"valid":field.valid},on:{"update:valid":function($event){return _vm.$set(field, "valid", $event)}},model:{value:(_vm.projeto[field.key]),callback:function ($$v) {_vm.$set(_vm.projeto, field.key, $$v)},expression:"projeto[field.key]"}})}),1)],1),_c('v-card-actions',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"color":"secondary","depressed":""},on:{"click":function($event){return _vm.doExitSave()}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary","depressed":""},on:{"click":function($event){return _vm.doSave()}}},[_vm._v("Salvar")])],1)],1)],1),_c('v-window-item',{attrs:{"eager":""}},[_c('DescricaoDispendios')],1),_c('v-window-item',{attrs:{"eager":""}},[_c('ParametrizacaoOrcamento')],1),_c('v-window-item',{attrs:{"eager":""}},[_c('OrcamentoDetalhado')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }