<template>
  <v-col cols="12">
    <v-card>
      <v-item-group>
        <v-item-group v-model="currentStep" class="text-center" mandatory>
          <v-item v-slot="{ active }">
            <v-btn
              dense
              text
              tile
              :style="{ opacity: active ? 1 : 0.8 }"
              @click="currentStep = 0"
              width="25%"
            >
              1 - Projeto
              <v-icon
                v-if="!canEdit"
                class="ml-3"
                small
                color="#999"
              >
                mdi-lock
              </v-icon>
            </v-btn>
          </v-item>
          <v-item v-slot="{ active }">
            <v-btn
              dense
              text
              tile
              :style="{ opacity: active ? 1 : 0.8 }"
              @click="currentStep = 1"
              width="25%"
            >
              2 - Pareamento de rubricas
              <v-icon
                v-if="!canEdit"
                class="ml-3"
                small
                color="#999"
              >
                mdi-lock
              </v-icon>
            </v-btn>
          </v-item>
          <v-item v-slot="{ active }">
            <v-btn
              dense
              text
              tile
              :style="{ opacity: active ? 1 : 0.8 }"
              @click="currentStep = 2"
              width="25%"
            >
              3 - Parametrização do orçamento
              <v-icon
                v-if="!canEdit"
                class="ml-3"
                small
                color="#999"
              >
                mdi-lock
              </v-icon>
            </v-btn>
          </v-item>
          <v-item v-slot="{ active }">
            <v-btn
              dense
              text
              tile
              :style="{ opacity: active ? 1 : 0.8 }"
              @click="currentStep = 3"
              width="25%"
            >
              4 - Orçamento detalhado
              <v-icon
                v-if="!canEdit"
                class="ml-3"
                small
                color="#999"
              >
                mdi-lock
              </v-icon>
            </v-btn>
          </v-item>
        </v-item-group>
      </v-item-group>
      <v-window v-model="currentStep">
        <v-window-item>
            <v-card>
              <v-card-title>
                {{ !isEdicaoProjeto ? 'Ficha de inclusão de projeto de captação' : 'Edição projeto captação'}}
              </v-card-title>
              <v-card-text>
                <v-row class="ml-n4 mr-0">
                  <input-v
                    v-for="(field, index) of cols"
                    class="pl-4"
                    :colSize="field.colSize"
                    :key="index"
                    :label="field"
                    :opts="opts"
                    :valid.sync="field.valid"
                    v-model="projeto[field.key]"
                  ></input-v>
                </v-row>
              </v-card-text>
              <v-card-actions class="d-flex justify-end">
                <v-btn color="secondary" depressed @click="doExitSave()">Cancelar</v-btn>
                <v-btn color="primary" depressed @click="doSave()">Salvar</v-btn>
              </v-card-actions>
            </v-card>
        </v-window-item>
        <v-window-item eager>
          <DescricaoDispendios></DescricaoDispendios>
        </v-window-item>
        <v-window-item eager>
          <ParametrizacaoOrcamento></ParametrizacaoOrcamento>
        </v-window-item>
        <v-window-item eager>
          <OrcamentoDetalhado></OrcamentoDetalhado>
        </v-window-item>
      </v-window>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';
import inputV from '@/components/input-v.vue';

export default {
    // props: {},
    components: {
      inputV,
      DescricaoDispendios: () => import('../descricao-dispendios/descricao-dispendios.vue'),
      ParametrizacaoOrcamento: () => import('../orcamentos/parametrizacao-orcamento.vue'),
      OrcamentoDetalhado: () => import('../orcamentos/orcamento-detalhado.vue'),
    },
    // mixins: [],
    // directives: {},
     data() {
         return {
          projeto: {
            nro_contrato: '',
            titulo: '',
            data_inicio: '',
            data_fim: '',
            status: null,
            responsavel_tecnico: '',
            responsavel_financeiro: '',
            data_protocolo: null,
            data_assinatura: null,
            percentual_comprovacao: null,
            referencia_contrato: ''
          },
          opts: {
            status: [
              {
                id: 1,
                name: 'Ativo',
              },
              {
                id: 2,
                name: 'Cancelado',
              },
              {
                id: 3,
                name: 'Finalizado',
              }
            ]
          },
          currentView: 0,
          currentStep: 0,
         }
     },
    computed: {
      ...mapGetters(['clientId']),
      isEdicaoProjeto() {
        return this.$route.query.projetoId ?? false;
      },
      projetoId() {
        return this.$route.query.projetoId;
      },
      cols() {
        return [
          {
            key: 'nro_contrato',
            name: 'Nº do contrato',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'titulo',
            name: 'Nome do projeto',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
            colSize: 8,
          },
          {
            key: 'data_inicio',
            name: 'Data início',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'data_fim',
            name: 'Data término',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'status',
            name: 'Status',
            type: this.$fieldTypes.SELECT,
            rel: { to: 'status', key: 'name', name: 'name' },
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'responsavel',
            name: 'Responsável técnico',
            type: this.$fieldTypes.TEXT,
            colSize: 6,
          },
          {
            key: 'responsavel_financeiro',
            name: 'Responsável financeiro',
            type: this.$fieldTypes.TEXT,
            colSize: 6,
          },
          {
            key: 'data_assinatura',
            name: 'Data assinatura',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'data_protocolo',
            name: 'Data protocolo',
            type: this.$fieldTypes.DATE,
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'percentual_comprovacao',
            name: 'Percentual de comprovação',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
            colSize: 4,
          },
          {
            key: 'referencia_contrato',
            name: 'Referência do contrato',
            type: this.$fieldTypes.TEXT,
            rules: [{ rule: 'required' }],
          },
        ]
      },
      resourceUrl() {
        return this.apiResource(`/v1/captacao/projetos/${this.clientId}`);
      }
    },
    // filters: {},
    created() {
      this.alteraTituloFormulario();
      if(this.isEdicaoProjeto) {
        this.resourceUrl.get({ id: this.projetoId }).then((response) => {
          this.projeto = response;
        })
      }
    },
    // mounted() {},
    // updated() {},
    // destroyed() {},
     methods: {
      alteraTituloFormulario() {
        return this.isEdicaoProjeto ? this.$route.meta.pageTitle = 'Edição projeto captação' : this.$route.meta.pageTitle = 'Cadastro projeto captação';
      },
      doExitSave() {
        this.$router.go(-1);
      },
      doSave() {
        const resource = this.resourceUrl;
        return resource.save({ ...this.projeto } ).then((response) => {
          return response;
        });
      }
    }
}
</script>

<style>

</style>
